import React from "react";
import { Box } from "@chakra-ui/react";

const BoxCard = ({ children, ...rest }) => (
  <Box
    p="1.25rem"
    boxShadow="0 3px 20px rgba(0,0,0,0.04)"
    borderRadius=".375rem"
    bg="white"
    color="black"
    {...rest}
  >
    {children}
  </Box>
);

export default BoxCard;
