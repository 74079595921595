import {
  Box,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { signOut } from "next-auth/client";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { DollarSign, Power, Settings, User } from "react-feather";
import getConfig from "next/config";

import Avatar from "@/components/common/Avatar";
import { useUser } from "@/hooks/useUser";
import { Website } from "@/types/website";
import paths from "@/utils/paths";
import { OfficeType } from "@/types/office";
import { useSettings } from "@/components/SettingsContext";

const UserMenu = ({
  openChangeRole,
  website,
}: {
  openChangeRole?: () => void;
  website?: Website;
}): React.ReactElement => {
  const router = useRouter();
  const [session] = useUser();
  const { t } = useTranslation("common");
  const { publicRuntimeConfig } = getConfig();
  const {
    settings: { isPageLoading },
  } = useSettings();
  const { basePath } = publicRuntimeConfig;

  const unactive = React.useMemo(() => {
    if (isPageLoading || !session?.currentRole) return false;
    return (
      !session.currentRole.subscribed &&
      (session.currentRole.role === "admin" ||
        session.currentRole.role === "mod") &&
      (session.currentRole.type === OfficeType.ORGANIZATION ||
        session.currentRole.type === OfficeType.PRESS)
    );
  }, [session, isPageLoading]);

  if (!session?.user?.id)
    return (
      <Link
        href={{
          pathname: website
            ? paths.getWebsiteHref(
                "/signin",
                website?.slug,
                website?.isSubdomain
              )
            : "/signin",
          query: {
            from: router.asPath !== "/" ? router.asPath : undefined,
          },
        }}
      >
        <Avatar
          transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
          transitionDuration=".3s"
          transitionProperty="background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter"
          _groupHover={{
            transform: "scale(1.05)",
          }}
          boxShadow="0 0 transparent,0 0 transparent,0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)"
          cursor="pointer"
        />
      </Link>
    );

  return (
    <Menu>
      <MenuButton>
        <Avatar
          src={session?.user?.profile?.picture}
          name={`${session?.user.firstName || ""} ${
            session?.user.lastName || ""
          }`}
          transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
          transitionDuration=".3s"
          transitionProperty="background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter"
          _groupHover={{
            transform: "scale(1.05)",
          }}
          boxShadow="0 0 transparent,0 0 transparent,0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)"
        />
      </MenuButton>
      <MenuList>
        <Box p=".5rem">
          <Text fontWeight="500">
            {session?.user.firstName} {session?.user.lastName}
          </Text>
          <Text
            fontSize=".75rem"
            lineHeight="1rem"
            mt=".125rem"
            color="text.400"
          >
            {t(session?.user?.role)}
          </Text>
        </Box>
        <MenuDivider />
        {website ? (
          <ChakraLink href={`${basePath}/profile`} isExternal>
            <MenuItem icon={<Settings />}>{t("users.myAccount")}</MenuItem>
          </ChakraLink>
        ) : (
          <Link href={"/profile"}>
            <MenuItem icon={<Settings />}>{t("users.myAccount")}</MenuItem>
          </Link>
        )}
        {openChangeRole && (
          <MenuItem
            onClick={openChangeRole}
            icon={<User />}
            style={
              unactive
                ? {
                    opacity: 0.5,
                    pointerEvents: "none",
                    textDecoration: "none",
                  }
                : null
            }
          >
            {t("users.changeRole")}
          </MenuItem>
        )}
        <MenuDivider />
        {(session?.currentRole?.type === "press" ||
          session?.currentRole?.type === "organization") && (
          <>
            <MenuItem
              icon={<DollarSign />}
              onClick={() => router.push("/payments")}
            >
              {t("users.payment")}
            </MenuItem>
            <MenuDivider />
          </>
        )}
        <MenuItem icon={<Power />} onClick={() => signOut({ redirect: false })}>
          {t("users.logout")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
