import React from "react";
import {
  AvatarProps,
  Text,
  chakra,
  useMultiStyleConfig,
  Skeleton,
  ChakraComponent,
  Tooltip,
} from "@chakra-ui/react";
import Image from "next/image";
import { getImageUri } from "@/utils/helpers";

function getInitials(name: string) {
  const [firstName, lastName] = name.split(" ");
  return firstName && lastName
    ? `${firstName.charAt(0)}${lastName.charAt(0)}`
    : firstName.charAt(0);
}

const DefaultIcon: ChakraComponent<"svg"> = (props) => (
  <chakra.svg
    viewBox="0 0 128 128"
    color="#fff"
    width="100%"
    height="100%"
    className="chakra-avatar__svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z"
    />
    <path
      fill="currentColor"
      d="M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24"
    />
  </chakra.svg>
);
interface CustomAvatarProps extends AvatarProps {
  tooltip?: string;
}

const Avatar = React.forwardRef(
  (props: CustomAvatarProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { name, src, tooltip, showBorder, ...boxProps } = props;

    const initials = React.useMemo(() => getInitials(name ?? ""), [name]);
    const [imageUnavailable, setImageUnavailable] = React.useState(!src);
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const styles = useMultiStyleConfig("Avatar", props);
    const [hasRendered, setHasRendered] = React.useState(false);
    const imgRef = React.useRef<HTMLImageElement | null>(null);

    React.useEffect(() => {
      if (src) setImageUnavailable(false);
      if (imgRef.current && hasRendered) {
        imgRef.current.src = src || "";
      }
    }, [src, hasRendered]);

    React.useEffect(() => {
      setHasRendered(true);
    }, []);

    const avatar = (
      <chakra.span
        ref={ref}
        flex="none"
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="100%"
        overflow="hidden"
        {...boxProps}
        __css={styles.container}
        borderWidth={showBorder ? "2px" : undefined}
      >
        {(imageUnavailable || !src) && (
          <Text textTransform="uppercase" fontWeight="500" __css={styles.label}>
            {initials}
          </Text>
        )}
        {(imageUnavailable || !src) && !initials && <DefaultIcon />}
        {src && !imageLoaded && !imageUnavailable && (
          <Skeleton w="100%" h="100%" />
        )}
        {!imageUnavailable && src && (
          <Image
            unoptimized
            ref={imgRef}
            objectFit="cover"
            src={getImageUri(src)}
            alt=""
            layout="fill"
            onError={() => setImageUnavailable(true)}
            onLoad={() => setImageLoaded(true)}
          />
        )}
      </chakra.span>
    );

    if (tooltip) return <Tooltip label={tooltip}>{avatar}</Tooltip>;

    return avatar;
  }
);

Avatar.displayName = "Avatar";

export default Avatar;
