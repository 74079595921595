import {
  ReactElement,
  JSXElementConstructor,
  ReactFragment,
  ReactPortal,
} from "react";
import { useToast, UseToastOptions } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type ToastStatus =
  | string
  | number
  | boolean
  | ReactElement<any, string | JSXElementConstructor<any>>
  | ReactFragment
  | ReactPortal;

type ToastPromiseParams = {
  pending?: ToastStatus;
  success?: ToastStatus;
  error?: ToastStatus;
  errorKeys?: { [x in string]: string };
};

export const useToastPromise = (options?: UseToastOptions) => {
  const { t } = useTranslation("common");
  const toast = useToast(options);
  const handlePromise = <T>(
    promise: Promise<T>,
    params: ToastPromiseParams = {}
  ) => {
    const {
      pending = t("dataSaving"),
      success = t("dataSaved"),
      error = t("dataSaveError"),
    } = params;
    const toastId = toast({
      status: "info",
      description: pending,
      duration: null,
    });

    const errorKeys = {
      ...params.errorKeys,
      ServerError: t("errorKeys.serverError"),
      WrongRole: t("errorKeys.wrongRole"),
      NotActiveSubscription: t("payments.NotActiveSubscription"),
    };

    return promise
      .then((result) => {
        toast.update(toastId, {
          status: "success",
          description: success,
          duration: 5000,
        });
        return result;
      })
      .catch((result) => {
        toast.update(toastId, {
          status: "error",
          description: errorKeys[result] ?? error,
          duration: 5000,
        });
        return result;
      });
  };

  return useMemo(() => ({ promise: handlePromise }), []);
};
