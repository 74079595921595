import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
const { APP_URL } = publicRuntimeConfig;

export default {
  forgotPassword: "/forgot-password",
  register: "/register",
  signIn: "/signin",
  signUp: "/singup",
  profile: "/profile",

  invitations: "/invitations",
  editors: "/editors",
  offices: "/offices",
  users: "/users",

  settingsTags: "/settings/tags",
  editSettingsTags: (id) => ({
    href: "/settings/tags/edit/[id]",
    as: `/settings/tags/edit/${id}`,
  }),

  websitePages: "/website/pages",
  addWebsitePage: "/website/pages/add",
  editWebsitePage: (id) => ({
    href: "/website/pages/edit/[id]",
    as: `/website/pages/edit/${id}`,
  }),

  website: {
    main: "/",
    accreditation: "/accreditation",
    audio: "/audio",
    contact: "/contact",
    photos: "/photos",
    video: "/video",
  },
  websiteArticle: (subdomain, isSubdomain, slug): string =>
    isSubdomain ? `/n/${slug}` : `/page/${subdomain}/n/${slug}`,
  getWebsiteHref: (page, subdomain, isSubdomain = false): string =>
    isSubdomain ? page : `/page/${subdomain}${page}`,
  getAppHref: (page, isSubdomain = false): string =>
    isSubdomain ? `${APP_URL}${page}` : page,
};
